import Container from "@material-ui/core/Container"
import React from "react"

const Layout: React.FC = ({ children }) => (
  <Container>
    <Container maxWidth="lg" disableGutters>
      <article id="page-content">{children}</article>
    </Container>
  </Container>
)

export default Layout
